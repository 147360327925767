<H4>{{pageTitle}}</H4>
<Ul>
    <li>
        <H5>[ANGULAR]</H5>
        <ul>
            <li><a href="https://angular.io/" target="_blank">[REFERENCIA]</a></li>
        </ul>
    </li>
    <li>
        <H5>[BOOTSTRAP]</H5>
        <ul>
            <li><a href="https://getbootstrap.com/" target="_blank">[REFERENCIA]</a></li>
        </ul>
    </li>
    <li>
        <H5>[MATERIAL]</H5>
        <ul>
            <li><a href="https://material.angular.io/" target="_blank">[REFERENCIA]</a></li>
        </ul>
    </li>
    <li>
        <H5>[TYPESCRIPT]</H5>
        <ul>
            <li><a href="https://www.typescriptlang.org/" target="_blank">[REFERENCIA]</a></li>
        </ul>
    </li>
</Ul>
<hr>
<a routerLink="/Home">Volver al Menú</a>



