<span>
    <H4>{{pageTitle}}</H4>
    <hr/>
    <UL>
        <li>Aplication Name    : {{appName}}</li>
        <li>Aplication Version : {{appVersion}}</li>
        <li>Source code <b>Front End</b>&nbsp;<a href="https://github.com/aperezNWO/AngularMCSD_Public" target="_blank">here</a></li>
        <li>Source code <b>Back End</b>&nbsp;<a href="https://github.com/aperezNWO/mcsd_public" target="_blank">here</a></li>
        <li>Angular Runtime    : <b>{{runtimeVersion}}</b></li>
        <li>WebApi  Version    : <b>{{webApiAppVersion}}</b></li>
    </UL>
    <hr>
    <a routerLink="/AAboutWeb">[Volver al Menú]</a>
    <hr>
    <input id="TestError" type="button" title="[TEST ERROR]" value="[TEST ERROR]" (click)="this.TestError();" [hidden]="false">
    <hr>    
</span>

