<div>
    <H4>{{pageTitle}}</H4>
    <h5>PABLO ALEJANDRO PEREZ ACOSTA</h5>
    <h5>NET PROGRAMMER</h5>
    <ul>
        <li><a href="javascript: void window.open('mailto:alejandro.perez.acosta@gmail.com')">EMAIL (alejandro.perez.acosta@hotmail.com)</a></li>
        <li><a href="javascript: void window.open('http://www.linkedin.com/in/pablo-alejandro-pérez-acosta-54765770')">LINKEDIN</a></li>
    </ul>
    <hr>
    <a routerLink="/AAboutWeb">[Volver al Menú]</a>
</div>