<div align="center" style="padding-top:10px;">
    <h4>{{pageTitle}}</h4>
</div>
<mat-tab-group>
    <mat-tab label="[CONSULTA_1 (LISTADO)]">
        <div align="center" style="padding-top:10px;">
          <h5>[CONSULTA_1 (LISTADO)]</h5>
          <div class="mat-elevation-z8">
            <table  mat-table  [dataSource]="csv_dataSource"  class="mat-elevation-z8" aria-label="rf_paginator">
                <ng-container matColumnDef="id_Column">
                  <th mat-header-cell *matHeaderCellDef>id_Column</th>
                  <td mat-cell *matCellDef="let element">{{ element.id_Column}}</td>
                </ng-container>
                <ng-container matColumnDef="ciudad">
                  <th mat-header-cell *matHeaderCellDef>ciudad</th>
                  <td mat-cell *matCellDef="let element">{{ element.ciudad }}</td>
                </ng-container>
                <ng-container matColumnDef="nombreCompleto">
                    <th mat-header-cell *matHeaderCellDef>nombreCompleto</th>
                    <td mat-cell *matCellDef="let element">{{ element.nombreCompleto }}</td>
                  </ng-container>
                <tr mat-header-row *matHeaderRowDef="this.csv_displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: this.csv_displayedColumns"></tr>
              </table>
              <!-- PAGINADOR / INICIO -->
              <hr>
              <mat-paginator #csv_paginator="matPaginator"
                  class="demo-paginator" 
                  [length]=""
                  [pageSize]="5"
                  [pageSizeOptions]="[5, 10, 25, 100]"
                  aria-label="csv_paginator"></mat-paginator>
              <!-- PAGINADOR / FIN    -->                  
              <!-- ENLACE DESCARGA / INICIO -->
              <hr />
              <div *ngIf="this.downloadLink">
                  <a [href]="this.downloadLink" id="DownloadFile" target="_blank">{{ this.downloadCaption }}</a>
              </div> 
              <!-- ENLACE DESCARGA FIN       -->
          </div>
        </div>
    </mat-tab>
    <mat-tab label="[CONSULTA_2 (ESTADISTICAS)]">
        <div align="center" style="padding-top:10px;">
            <h5>[CONSULTA_2 (ESTADISTICAS)]</h5>
            <!-- PANEL GRAFICO / INICIO  -->
            <div id="divPieChart_CSV" #divPieChart_CSV class="chart-container" style="display: block; box-sizing: border-box; height: 400px; width: 400px;padding:5px;" height="400" width="400">
                <canvas  id="canvas_csv" #canvas_csv style="background-color:bisque;padding:10px 10px 10px 10px;">{{ this.pieChartVar }}</canvas>
            </div>
            <!-- PANEL GRAFICO / FIN     -->
            <!-- GENERAR PDF / INICIO -->
            <hr />
            <div id="divdescargapdf" style="display: block" align="right">
                <input id="GetPDF" type="button" title="[GENERAR PDF]" value="[GENERAR PDF]" (click)="GetPDF()">           
            </div>
            <!-- GENERAR PDF / FIN    -->
        </div>
    </mat-tab>               
</mat-tab-group>
<hr>
<a routerLink="/FilesGenerationWeb">Volver al Menú</a>   