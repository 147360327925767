<div class="main-container">
    <H4>{{pageTitle}}</H4>
    <Ul>
        <li>
                <a routerLink="/Contact">[CONTACTO]</a>
        </li>
        <li>
                <a routerLink="/TechnicalSpecs">[ESPECIFICACIONES TECNICAS]</a>
        </li>
        <li>
                <a href="" target="_blank">[COMENTARIOS] (LOGIN REQUIRED)</a>
        </li>
        <li>
                <a href="" target="_blank">[JOB SEARCH] (LOGIN REQUIRED)</a>
        </li>
        <li>
                <a href="" target="_blank">[LOGIN]</a>
        </li>
    </Ul>
    <hr>
    <a routerLink="/Home">Volver al Menú</a>
</div>    