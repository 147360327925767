<header class="subtitle">
<div class="navigation subtitle">
  <nav class="navbar navbar-expand-lg navbar-light bg-light subtitle">
      <a class="navbar-brand" href="#">
        <div class="subtitle">
          <h2>{{_title}}</h2>
        </div>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse"     
      data-target="#navbarItems" aria-controls="navbarItems"                
      aria-expanded="false" aria-label="Toggle navigation" 
      (click)="this.NavbarCollapsed = !this.NavbarCollapsed">
        <span class="navbar-toggler-icon"></span>
    </button>
      <div class="collapse navbar-collapse subtitle" id="navbarItems" #navbarItems [ngbCollapse]="this.NavbarCollapsed">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link" routerLink="/Home"><span class="sr-only">{{HomeWebComponent_pageTitle}}</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/FilesGenerationWeb"><span>{{FilesGenerationWebComponent_pageTitle}}</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/AlgorithmWeb"><span>{{AlgorithmWebComponent_pageTitle}}</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/AngularTutorialsnWeb"><span>{{AngularTutorialsnWebComponent_pageTitle}}</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/AAboutWeb"><span>{{AAboutWebComponent_pageTitle}}</span></a>
          </li>
        </ul>
      </div>
  </nav>
</div>
</header>
<hr/>
<router-outlet></router-outlet>
<hr/>
<div class="footer">
    <h5>Aplication Name    : {{this._appName}}</h5>
    <h5>Aplication Version : {{this._appVersion}}</h5>
</div>
<hr/>

