<H4>[INDICE]</H4>
<H5><a routerLink="/FilesGenerationWeb">[GENERAR ARCHIVOS]</a></H5>
<ul>
    <li><a routerLink="/FilesGenerationCSV">[GENERAR ARCHIVO CSV]</a></li>
    <li><a routerLink="/FilesGenerationXLS">[GENERAR ARCHIVO XLS]</a></li>
    <li><a routerLink="/FilesGenerationPDF">[GENERAR ARCHIVO PDF]</a></li>
    <li><a routerLink="/FilesGenerationZIP">[GENERAR ARCHIVO ZIP]</a></li>
</ul>
<H5><a routerLink="/AlgorithmWeb">[ALGORITMOS]</a></H5>
<ul>
    <li><a routerLink="/AlgorithmRegEx">[ALGORITMOS - EXPRESIONES REGULARES]</a></li>
    <li><a routerLink="/AlgorithmSort">[ALGORITMOS - ORDENAMIENTO]</a></li>
    <li><a routerLink="/AlgorithmDijkstra">[ALGORITMOS - DISTANCIA MAS CORTA]</a></li>
</ul>
<H5><a routerLink="/AngularTutorialsnWeb">[TEMAS]</a></H5>
<Ul>
    <li>
        <H5>[ANGULAR]</H5>
        <ul>
            <li><a href="https://angular.io/" target="_blank">[REFERENCIA]</a></li>
        </ul>
    </li>
    <li>
        <H5>[BOOTSTRAP]</H5>
        <ul>
            <li><a href="https://getbootstrap.com/" target="_blank">[REFERENCIA]</a></li>
        </ul>
    </li>
    <li>
        <H5>[MATERIAL]</H5>
        <ul>
            <li><a href="https://material.angular.io/" target="_blank">[REFERENCIA]</a></li>
        </ul>
    </li>
    <li>
        <H5>[TYPESCRIPT]</H5>
        <ul>
            <li><a href="https://www.typescriptlang.org/" target="_blank">[REFERENCIA]</a></li>
        </ul>
    </li>
</Ul>
<H5><a routerLink="/AAboutWeb">[ACERCA DE]</a></H5>
<Ul>
    <li>
            <a routerLink="/Contact">[CONTACTO]</a>
    </li>
    <li>
            <a routerLink="/TechnicalSpecs">[ESPECIFICACIONES TECNICAS]</a>
    </li>
    <li>
            <a href="" target="_blank">[COMENTARIOS] (LOGIN REQUIRED)</a>
    </li>
    <li>
            <a href="" target="_blank">[JOB SEARCH] (LOGIN REQUIRED)</a>
    </li>
    <li>
        <a href="" target="_blank">[LOGIN]</a>
    </li>
</Ul>
<hr>