<div class="main-container">
    <div class="content">
        <div class="content-body">
            <div align="center" style="padding-top:10px;">
                <h4>{{this.pageTitle}}</h4>
            </div>
            <p>Ver referencia&nbsp;<a href="javascript: void window.open('https://en.wikipedia.org/wiki/Dijkstra\'s_algorithm')">[Algoritmo Dijkstra]</a></p>
            <p>Ver referencia&nbsp;<a href="javascript: void window.open('https://www.npmjs.com/package/jspdf')">[jsPDF]</a></p>
        </div>
        <hr />
        <div id="LangListDiv">
            <span id="tituloListadoLenguajes">{{this.tituloListadoLenguajes}}</span>
            <br>
            <label for="LangList"></label>
            <br>
            &nbsp;<select   id="LanguageList"
                            name="LanguajeList"
                            #_languajeList
                            (change)="this._cppSourceDivHiddenChaged()">
                            <option *ngFor="let _languageName of __languajeList" [value]="_languageName._index">{{_languageName._value}}</option>
            </select>
            &nbsp;&nbsp;
            <span id="cppSoureDiv" [hidden]="this._cppSourceDivHidden">
               <a href="javascript: void window.open('https://github.com/aperezNWO/CppBuilder01/blob/master/Project2/Dijkstra.cpp')">[View Source]</a>
            </span>
        </div>
        <hr />
        <div id="listTitle" [hidden]="((this.PointListHidden.length == 0) && (this.MatrixListHidden.length == 0))">
            <span id="tituloListadoDistancias">{{this.tituloListadoDistancias}}</span>
            <br>
            <label for="DistanceList"></label>
            <br>
            &nbsp;<select   id="DistanceList"
                            name="DistanceList"
                            #_distanceList
                            (change)="this._distanceListChange()">
                            <option *ngFor="let _vertexSize of __distanceList" [value]="_vertexSize._index">{{_vertexSize._value}}</option>
            </select>
        </div>
        <div>
            <!-- PANEL GRAFICO / INICIO -->
            <div id="divCanvas_Pdf" #divCanvas_Pdf style="display: block; box-sizing: border-box; height: 255px; width: 255px;padding:5px;" height="255" width="255">
                <canvas id="c_canvas" #c_canvas width="250px" height="250px">
                </canvas>
            </div>
            <!-- PANEL GRAFICO / FIN     -->
        </div>
        <hr />
        <!-- P_DATA_SOURCE_ID        -->
        <P>
            <label for="_vertexSizeList">Cantidad de Puntos:</label>
            <select     id="_vertexSizeList"
                        name="_vertexSizeList"
                        #_vertexSizeList
                        (change)="this._vertexSizeListChange();">                    
            <option *ngFor="let _vertexSize of __vertexSizeList" [value]="_vertexSize._index">{{_vertexSize._value}}</option>
            </select>
        </P>
        <hr />
        <p>
            <label for="_sourcePointList">Punto de Origen:</label>
            <select     id="_sourcePointList"
                        name="_sourcePointList"
                        #_sourcePointList>                    
            <option *ngFor="let _vertexSize of __sourcePointList" [value]="_vertexSize._index">{{_vertexSize._value}}</option>
            </select>
        </p>
        <hr />
        <div>
            <input id="GetGraph" type="button" title="[GENERAR]"   value="[GENERAR]"   (click)="this._GetGraph()" 
            [disabled]="!((this.PointListHidden.length == 0) && (this.MatrixListHidden.length == 0))">
            |
            <input id="NewGraph" type="button" title="[REINICIAR]" value="[REINICIAR]" (click)="this._ResetControls()"
            [disabled]="((this.PointListHidden.length == 0) && (this.MatrixListHidden.length == 0))">
        </div>
        <hr />
            <input id="GetPDF" type="button" title="[GENERAR PDF]" value="[GENERAR PDF]" (click)="this._GetPDF()"
            [disabled]="  ( (this.PointListHidden.length  == 0) 
                         && (this.MatrixListHidden.length == 0))
                         || (this.selectedIndex           == 0)">
        <hr />
        <a routerLink="/AlgorithmWeb">Volver al Menú</a>
    </div>
</div>


