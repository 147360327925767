<div class="content">
    <div class="content-body">
        <p>{{this.pageTitle}}</p>
        <p>Ver referencia&nbsp;<a href="javascript: void window.open('https://en.wikipedia.org/wiki/Bubble_sort')">[BUBBLE SORT]</a></p>
        <p>Ver referencia&nbsp;<a href="javascript: void window.open('https://en.wikipedia.org/wiki/Quicksort')">[QUICK SORT]</a></p>
        <p>Ver referencia&nbsp;<a href="javascript: void window.open('https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle')">[FISHER/YATES SHUFFLE]</a></p>
    </div>
    <hr>
    <p>Listado a Ordenar</p>
        <div id="mensajes_1" #mensajes_1 class="mensajes">
        </div>
    <br/>        
    <p>Listado Oredenado</p>        
        <div id="mensajes_2" #mensajes_2 class="mensajes">
        </div>
    <div id="mensajes" #mensajes style="visibility: hidden;height:0px;">
    </div>
    <hr>
    <div>
        <canvas id="c_canvas" #c_canvas width="250px" height="250px"></canvas>
    </div>
    <hr>
    <div>
        <span id="lblstatus">{{this.lblStatus}}</span>
    </div>
    <hr>
    <div>
        ALGORITMO :
        <select id="SortAlgorithmList" #SortAlgorithmList
        [disabled]="!((this.GetSortLabel != '[...ordenando...]') && (this.GetSortLabel != '[...ordenado...]'))">
            <option value="0">[SELECCIONE_OPCION]</option>
            <option value="1">BUBBLE SORT</option>
            <option value="2">QUICK SORT</option>
        </select>
    </div>
    <hr>
    <div id="LangListDiv">
        <span id="tituloListadoLenguajes">{{this.tituloListadoLenguajes}}</span>
        <br>
        <label for="LangList"></label>
        <br>
        &nbsp;<select   id="LanguageList"
                        name="LanguajeList"
                        #_languajeList
                        (change)="this._cppSourceDivHiddenChaged()">
                        <option *ngFor="let _languageName of __languajeList" [value]="_languageName._index">{{_languageName._value}}</option>
        </select>
        &nbsp;&nbsp;
        <span id="cppSoureDiv" [hidden]="this._cppSourceDivHidden">
           <a href="javascript: void window.open('https://github.com/aperezNWO/CppBuilder01/blob/master/Project2/SortBenchMark.cpp')">[View Source]</a>
        </span>
    </div>
    <hr />
    <div>
        <input id="NewSort" type="button" title="[REINICIAR]" value="[REINICIAR]" 
        (click)="this.GetNewSort()" 
        [disabled]=" !(this.lblStatus == '[SE ORDENO CORRECTAMENTE EL LISTADO]')  ">
        |
        <input id="GetSort" type="button" title="[ORDENAR]" value={{this.GetSortLabel}} 
        (click)="this.GetSort()"
        [disabled]=" !( (this.GetSortLabel != '[...ordenando...]') && (this.GetSortLabel != '[...ordenado...]') )">
    </div>
    <hr>
    <a routerLink="/AlgorithmWeb">Volver al Menú</a>
</div>
